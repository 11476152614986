
import React, {useEffect} from 'react';
import { useState } from 'react';
import Form from "react-bootstrap/Form";
import FirstStep from "./FirstStep";
import PaymentStep from "./PaymentStep";
import SecondStep from "./SecondStep";
import ThankYou from "../ThankYou";
import Temp from "../Temp";


const Application= () => {
    const [validFirstStep, setValidFirstStep] = useState(false);
    const [validSecondStep, setValidSecondStep] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
     const [showTemp, setShowTemp] = useState(true);
    const [countSentEmails, setCountSentEmails] = useState(0);
    const [message, setMessage] = useState('');
    const [messageSSN, setMessageSSN] = useState('');
    const [cost, setCost] = useState('0');
    //debugging issues
    const [submitStartTime, setSubmitStartTime] = useState(0);
        const [salesforceContactID, setSalesforceContactID] = useState('');
    const [formData, setFormData] = useState({
        ssn: '',
        school: {"id": "", "value": ""},
        courses: [],


    });

    //use this for temp open website
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('off');

        if (myParam == 1 )  {
            setShowTemp(false);
        }
    }, []);


    useEffect(() => {

        setFormData(prevData => ({
            ...prevData,
            courses: []
        }));
        setCost(0);

    }, [ formData.school, formData.grade]);

    useEffect(() => {

        setMessage('');

    }, [ formData]);


    const handleChange = (event) => {
        const {  value , id, name, type} = event.target;
        let formid = id ;
 if (type==='radio') {
     //use name instead of id;
     formid= name;
 }
        setFormData({
            ...formData,
            [formid ]: value,
        });
    };
    const checkDuplicateSSN = (SSN) => {
        //start on if valid on first step
        setMessage('');

                /*start sending data*/

                fetch(process.env.REACT_APP_SFDC_INSTANCE + "/services/data/v61.0/query?q=SELECT+id+from+Contact+where+RecordTypeId=\'" + process.env.REACT_APP_RECORD_TYPE_ID + "\'+AND+SSN__c=\'" + SSN+ "\'", {
                    method: 'GET',
                   headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,

                    },


                })
                    .then(response => {
                        if (!response.ok) {
                            return response.json().then(errorResponse => {
                                throw new Error(JSON.stringify(errorResponse));
                            });
                        }
                        return response.json(); // This returns a promise
                    })
                    .then(data => {
                        //checking just SSN

                        if (data.totalSize > 0)  {

                            setSalesforceContactID(data.records[0].Id);
                            //if has ssn, now check with same year

                                    /*start sending data*/

                                    fetch(process.env.REACT_APP_SFDC_INSTANCE + "/services/data/v61.0/query?q=SELECT+id+from+Contact+where+RecordTypeId=\'" + process.env.REACT_APP_RECORD_TYPE_ID + "\'+AND+SSN__c=\'" +SSN+ "\'+and+Semester_Applied__c='Fall 2024' ", {
                                        method: 'GET',

                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,

                                        },

                                    })
                                        .then(response => {
                                            if (!response.ok) {
                                                return response.json().then(errorResponse => {
                                                    throw new Error(JSON.stringify(errorResponse));
                                                });
                                            }
                                            return response.json(); // This returns a promise
                                        })
                                        .then(data => {


                                            if (data.totalSize > 0)  {


                                                setMessageSSN('Our records indicate that you\'ve already enrolled in Dual courses for this year.\n' +
                                                    'In order to enroll in an additional course(s), please reach out to Mrs. Lieba Berger at lberger@sarasch.com or 718-633-8557 ext. 56.'   );
                                                setValidFirstStep(true);
                                                return false;
                                            }



                                        })

                                        .catch(error => {
                                            console.log("timed out");
                                            sendEmail('error', 'check ssn with year: ' + error);
                                        });
                                    /*end sending in data*/
                                }

                            //end check with SSN and course year

                        })





                    .catch(error => {
                        console.log("timed out ");
                        sendEmail('error', 'check ssn line 156: ' + error);
                    })
                    .finally(() => {

                    });
                /*end sending in just ssn*/





    }

    function getFormattedDateTime() {
        // Create a new Date object
        const now = new Date();

        // Get the user's timezone offset in minutes
        const timezoneOffset = now.getTimezoneOffset();

        // Adjust the date for the user's timezone
        const adjustedTime = now.getTime() + timezoneOffset * 60000;

        // Format the date and time in the desired format (MDT)
        const options = {
            timeZone: 'America/Denver',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(adjustedTime));

        return formattedDateTime;
    }



    const SubmitFirstStep = (event) => {

        const form = event.currentTarget.closest('form');
        const invalidElements = form.querySelectorAll(':invalid');


        if (form.checkValidity() === false || !formData.school) {
            if (invalidElements.length > 0) {
                invalidElements[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
                invalidElements[0].focus();
            }


            setValidFirstStep(false);
            setValidated(true)

        } else {
            setValidFirstStep(true);
            // console.log('valid step 2');
            setValidated(false)
            //scroll to bottom of the page
            //   window.scrollTo(0,document.body.scrollHeight);



        }

    };
    const SubmitSecondStep = (event) => {

        const form = event.currentTarget.closest('form');
        if (form.checkValidity() === false || formData.courses.length === 0 ) {


            setValidSecondStep(false);
            setValidated(true)

        } else {
            setValidSecondStep(true);

            setValidated(false)
            //scroll to bottom of the page
            window.scrollTo(0,document.body.scrollHeight);

        }

    };

    const handleSubmit = (event) => {
//make sure didn't lose courses or amount at this point. it's probably after paid
        const form = event.currentTarget;
        event.preventDefault();


        if (form.checkValidity() === false ) {

            event.stopPropagation();


        } else {

            //  submitSalesForce();


        }
        setValidated(true)
    };

    const sendEmail =(type="confirmation", message= '') => {
        if (countSentEmails > 5 )  {
            console.log("Error Emails");
            return false;
        }
        setCountSentEmails(countSentEmails+1);
        var html = "";
        if(message)  {
            html += message;
        }

         html += "<table>";
        for (var key in formData) {
            if (key !== 'cardNumber' && key !== 'ssn') {
                if (typeof formData[key] === 'object') {
                    html += "<tr><td>" + key + "</td><td></td></tr>";
                    for (var k in formData[key]) {
                        if (typeof formData[key][k] === 'object') {
                            html += "<tr><td>" + k + "</td><td></td></tr>";
                            for (var j in formData[key][k]) {
                                html += "<tr><td>" + j + "</td><td>" + formData[key][k][j] + "</td></tr>";
                            }
                        } else {
                            html += "<tr><td>" + k + "</td><td>" + formData[key][k] + "</td></tr>";
                        }
                    }
                } else {
                    html += "<tr><td>" + key + "</td><td>" + formData[key] + "</td></tr>";
                }
            }
        }



        html += "</table><br> User Agent: ";
        html += navigator.userAgent;
        html += '<br>Emails Sent: ' + countSentEmails;
        html += '<br>Start Time: ' + submitStartTime;
        html += '<br>End Time: ' + getFormattedDateTime();
//TODO move to .env
let template_id = (type === 'confirmation') ? 'template_jvpwa3n' : 'template_v5iamna';
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                service_id: 'service_gactk3d',
                template_id: template_id,
                user_id: 'qFcDREpWbv9u7ZL66',
                template_params: {
                    html:  html,
                    variables:  formData,
                }
            })
        })
            .then(response => {
                console.log('Success');
            })

            .catch(error => {
                setMessage(`Sorry, there was a technical error with your application and you were not enrolled in any courses. 
                    You must reach out to lberger@sarasch.com to complete your registration. 
                    Your card has been charged so please do not submit the application again.  `);

            });
    }
    const submitSalesForce =() => {

        setMessage('Submitting...');
       setSubmitStartTime(getFormattedDateTime());

        const courseIds = formData.courses.map(course => course.id).join(',');
        /*start sending data*/
        // console.log('submitting form', formData);
        let updateurl = (salesforceContactID) ? '/services/data/v61.0/sobjects/Contact/' +  salesforceContactID : '/services/data/v61.0/composite/sobjects/';

        let method = (salesforceContactID) ? 'PATCH' : 'POST';
        let jsonTosend = {};

        jsonTosend = {

            "attributes": {"type": "Contact"},

            "FirstName" :  formData.firstName.charAt(0).toUpperCase() + formData.firstName.slice(1),
            "LastName": formData.lastName.charAt(0).toUpperCase() + formData.lastName.slice(1),
            "MiddleName": formData.middleName  ? formData.middleName.charAt(0).toUpperCase() + formData.middleName.slice(1) : '',
            "SSN__c": formData.ssn,
            "US_Citizen__c": formData.usCitizen,
            "Birthdate": formData.dob,
            "Gender__c": formData.gender,
            "Email": formData.email,
            "HomePhone": formData.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            "MobilePhone": formData.cell.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
            "Texting__c": formData.allowTexting,
            "MailingStreet": formData.address,
            "MailingCity": formData.city,
            "MailingState": formData.state,
            "MailingPostalCode": formData.zip,

            "Emergency_Contact_Details_JSON__c":
                "name:" + formData.guardianFirstName + ' ' + formData.guardianLastName +
                "-phone:" +   formData.guardianPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') +
                   "-email:" + formData.guardianEmail +
                "-relationship:" + formData.guardianRelationship  ,

            "Dual_High_School__c": formData.school.value,
            "CEEB_Dual_High_School__c": formData.school.id,
            "Dual_Grade__c": formData.grade,
            "DualApplicationCourseIDs__c": courseIds,
            "Card_Name__c": formData.cardName,
            "Dual_Payment_Confirmation__c": formData.authCode,
            "Dual_Payment_Total__c": cost,
            "College_Attending__c": formData.school.college,
            "Payment_Created_Date__c" :new Date().toISOString(),
             "RecordTypeId": process.env.REACT_APP_RECORD_TYPE_ID,//DUAL RECORD
            "Payment_Method__c" : formData.cleanedCardNumber,
            "Payment_Status__c" : 'capturedPendingSettlement',
            "Semester_Applied__c": "Fall 2024" //change every year



        };


        if (!salesforceContactID) {

            jsonTosend = {
                "allOrNone": false,
                "records": [jsonTosend]
            };
        }

        fetch(process.env.REACT_APP_SFDC_INSTANCE + updateurl, {
        // fetch('http://www.google.com/'+ updateurl, {

            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,

            },
            body: JSON.stringify(jsonTosend)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorResponse => {
                        throw new Error(JSON.stringify(errorResponse));
                    });
                }
                if (response.status === 204) {
                    return null; // No need to parse an empty body
                }
                return response.json(); // This returns a promise
            })
            .then(data => {
                if (data === null) {
                   // console.log('Update successful');
                } else {

                    if (data[0].success === false) {
                        if (data[0].errors[0].statusCode === 'DUPLICATE_VALUE') {
                            setMessage('Our records indicate that you\'ve already enrolled in Dual courses for this year.\n' +
                                'In order to enroll in an additional course(s), please reach out to Mrs. Lieba Berger at lberger@sarasch.com or 718-633-8557 ext. 56.\n');

                            return false;
                        }
                        const error = data[0].errors[0].message;
                        setMessage(`Sorry, there was a technical error with your application and you were not enrolled in any courses.
                       You must reach out to lberger@sarasch.com to complete your registration.
                          Your card has been charged so please do not submit the application again. `);
                        //turn this into string data[0].errors[0]
                        var stringError = JSON.stringify(data[0].errors[0]);

                        sendEmail('error', 'line 399: ' + stringError);

                        return false;
                    }
                }


                let contactID =(!salesforceContactID? data[0].id : salesforceContactID);
                let paymenturl = '/services/data/v61.0/composite/sobjects/';
                let paymentmethod = 'POST';

                let paymentJson =
                    {
                        "allOrNone": false,
                        "records": [
                            {
                                "attributes": {
                                    "type": "payment__c"
                                },
                                "Contact__c": contactID,
                                "Transaction_Id__c": formData.authCode
                                ,"Transaction_Status__c" : "capturedPendingSettlement",
                                "Transaction_Amount__c" : cost,
                                "Transaction_Response__c" :1
                            }
                        ]
                    }
                fetch(process.env.REACT_APP_SFDC_INSTANCE + paymenturl, {

                    method: paymentmethod,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + process.env.REACT_APP_TOKEN,

                    },
                    body: JSON.stringify(paymentJson)
                })
                    .then(response => {
                        if (!response.ok) {
                            return response.json().then(errorResponse => {
                                throw new Error(JSON.stringify(errorResponse));
                            });
                        }
                        if (response.status === 204) {
                            return null; // No need to parse an empty body
                        }
                        return response.json(); // This returns a promise
                    })
                    .then(data => {
                        sendEmail();
                        setShowThankYou(true);
                    })
                    .catch(error => {
                        setMessage(`Sorry, there was a technical error with your application and you were not enrolled in any courses.
                    You must reach out to lberger@sarasch.com to complete your registration. 
                    Your card has been charged so please do not submit the application again.  `);
                        sendEmail('error', 'salesforce sending payment object: ' + error);
                    });



                // Handle the successful response here
            })

            .catch(error => {

                sendEmail('error', 'salesforce sending main object: ' + error);

                    setMessage(`Sorry, there was a technical error with your application and you were not enrolled in any courses.
                    You must reach out to lberger@sarasch.com to complete your registration. 
                    Your card has been charged so please do not submit the application again.  `);




            });
        /*end sending in data*/


    }
    //  code for temp
    //  {showTemp  ? (
    //                <Temp />  ) : (
    return (
        <>
             {showTemp  ? (
                           <Temp />  ) : (

                !showThankYou ? (
                <Form noValidate  onSubmit={handleSubmit} validated={validated} className="mb-4">


                    {validFirstStep && validSecondStep   ? (
                        <PaymentStep cost={cost} sendEmail ={sendEmail} validated={validated} setValidated={setValidated} setValidSecondStep={setValidSecondStep}  onSubmit={submitSalesForce} formData={formData} setFormData={setFormData} handleChange={handleChange} />
                    ) : (
                        <>
                            <FirstStep   validFirstStep={validFirstStep}  SubmitFirstStep ={SubmitFirstStep}  formData={formData} setFormData={setFormData} handleChange={handleChange} messageSSN={messageSSN} checkDuplicateSSN={checkDuplicateSSN} />

                            {validFirstStep && (
                                <SecondStep cost={cost} setCost={setCost} SubmitSecondStep={SubmitSecondStep} validSecondStep={validSecondStep}   formData={formData} setFormData={setFormData} handleChange={handleChange}/>
                            )}
                        </>
                    )}
                    {message && <div className="alert alert-primary mt-4 mb-4 ">{message}</div>}
                </Form>
            ) :  (
                <ThankYou formData={formData} />
            )
                )}
        </>

    );
}



export default Application;
