import React from 'react';

import Row from "react-bootstrap/Row";


const Temp= (   ) => {


    return (
        <>

            <Row className="mb-3 text-start">
                <h4 className="text-center">Registration is now closed. If you have any questions, reach out to Mrs. Lieba Berger at <a href="mailto:lberger@sarasch.com" target="_blank">lberger@sarasch.com.</a></h4>




            </Row>
            </>
            );
}



            export default Temp;
