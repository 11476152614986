import React from 'react';

import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import SchoolListing from "./SchoolListing";
import Button from "react-bootstrap/Button";


//https://sarasch--partialdev.sandbox.lightning.force.com/lightning/setup/ObjectManager/Contact/FieldsAndRelationships/view

const FirstStep= (props) => {
    const {formData, setFormData, handleChange, SubmitFirstStep, validFirstStep, checkDuplicateSSN, messageSSN} = props;


const validateSSN = (event) => {
    const { value } = event.target;
    event.target.setCustomValidity('');

    const invalidSSN =  ['000-00-0000', '111-11-1111'
        ,'222-22-2222'
        ,'333-33-3333'
        ,'444-44-4444'
        ,'555-55-5555'
        ,"666-66-6666"
        ,'777-77-7777'
        ,'888-88-8888'
        ,'999-99-9999',
        '123-45-6789',
        '987-65-4321',
        '012-34-5678'];


    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
    if (match ) {
        let newSSN= [match[1], match[2], match[3]].filter(Boolean).join('-');
        setFormData({
            ...formData,
            ssn: newSSN
        })
        if (value.length> 10   && invalidSSN.includes(value)){
            event.target.setCustomValidity('Invalid SSN');
            return false;
        }

            if (value.length === 11   ) {
               checkDuplicateSSN(newSSN);
            }
    }
}

        return (
        <>
<Row   >
    {/*<h1>  Sara Schenirer Late Dual Credit Course Registration</h1>*/}

    <div className="fw-bold alert alert-info">Prior authorization from Mrs. Lieba Berger is required to register for courses at this time</div>

               <p> For any questions, reach out to Mrs. Lieba Berger at <a href="mailto:lberger@sarasch.com">lberger@sarasch.com</a></p>
</Row>
            <Row className="mb-1 mt-4 text-start">
                <h2>Personal Information</h2>
            </Row>
            <Row className="mb-3 text-start">
                <div className="mt-2 mb-2 small">
                    Please provide your full legal name as it appears on your government ID.

                </div>
                <Form.Group as={Col} md="4" controlId="firstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        onChange={handleChange}
                        value={formData.firstName || ""}

                    />

                </Form.Group>
                <Form.Group as={Col} md="4" controlId="middleName">
                    <Form.Label>Middle name</Form.Label>
                    <Form.Control

                        type="text"
                        placeholder="Middle name"
                        onChange={handleChange}
                        value={formData.middleName || ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>  <Form.Group as={Col} md="4" controlId="lastName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    onChange={handleChange}
                    value={formData.lastName || ""}
                />

            </Form.Group>

            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="6" controlId="ssn">
                    <Form.Label>Social Security Number</Form.Label>
                    <Form.Control type="text" placeholder="XXX-XX-XXXX" required   value={formData.ssn || ""}     maxLength="11" minLength="11"    onChange={validateSSN}
                    pattern="^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$" />
                    <Form.Control.Feedback type="invalid">
                        Invalid
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="usCitizen">
                    <Form.Label>Are you a US Citizen?</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                    <Form.Check type="radio" name="usCitizen" id="usCitizen_01" value="true" required  inline    checked={formData.usCitizen === "true"}    label="Yes"     onChange={handleChange} />
                    <Form.Check type="radio" name="usCitizen" value="false"  id="usCitizen_00" required  inline     checked={formData.usCitizen === "false"}     label="No"  onChange={handleChange} />
                    </div>
                </Form.Group>
                {messageSSN && <div className="alert alert-primary mt-4 mb-4 ">{messageSSN}</div>}
                <Form.Group as={Col} md="6" controlId="dob">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" placeholder="mm/dd/YYYY" required
                                  value={formData.dob || ""}
                                  min={(new Date(new Date().setFullYear(new Date().getFullYear() - 50))).toISOString().split('T')[0]}
                                  max={(new Date(new Date().setFullYear(new Date().getFullYear() - 13))).toISOString().split('T')[0]}    onChange={handleChange} />
                    <div className="invalid-feedback">
                        Please enter a valid birth date.
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="gender">
                    <Form.Label>Gender</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                            type="radio"
                            name="gender"
                            value="male"
                            required
                            inline
                            label="Male"
                            id="gender_0"
                            checked={formData.gender === "male"}
                            onChange={handleChange}
                        />

                        <Form.Check
                            type="radio"
                            name="gender"
                            value="female"
                            required
                            inline
                            id="gender_1"
                            checked={formData.gender === "female"}
                            label="Female"
                            onChange={handleChange}
                        />
                    </div>
                </Form.Group>

            </Row>
            <Row className="mb-3 text-start">
                <h3>Email</h3>
                <div className="mb-2 small mt-2"><p className="mb-0">This must be a personal, not school, email address. All future correspondence, including the itemized receipt for this registration, will be sent to this email address.</p>
                    <p className="fst-italic">Due to restrictions with Yahoo and AOL addresses, a Gmail address is preferred.</p>
                </div>

                <Form.Group as={Col} md="6" controlId="email">
                    <Form.Control type="email" placeholder="Email" required
                                  value={formData.email || ""}
                                  onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="confirmEmail">
                    <Form.Control type="email" placeholder="Confirm Email"  isInvalid={ (formData.email && formData.confirmEmail) && (formData.email !== formData.confirmEmail)}
                                  onChange={handleChange}
                                  value={formData.confirmEmail || ""}
                                  required/>

                    <Form.Control.Feedback type="invalid">
                        Email must match
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="4" controlId="phone">
                    <Form.Label>Home Phone</Form.Label>
                    <Form.Control type="text" placeholder="Phone" required     pattern="[0-9]*" minLength="10"
                                  value={formData.phone || ""}
                                  onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid phone.
                    </Form.Control.Feedback>
                </Form.Group>
   <Form.Group as={Col} md="4" controlId="cell">
                    <Form.Label>Cell Phone</Form.Label>
                    <Form.Control type="text" placeholder="Cell Phone" required  value={formData.cell || ""}   pattern="[0-9]*"    minLength="10"      onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid phone.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="allowTexting">
                    <Form.Label> Does this phone have texting?</Form.Label>
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check type="radio" name="allowTexting"  id="allowTexting_00" value="yes" required  inline     checked={formData.allowTexting === "yes"} label="Yes"     onChange={handleChange} />
                        <Form.Check type="radio" name="allowTexting"  id="allowTexting_01" value="no" required  inline     checked={formData.allowTexting === "no"}  label="No"  onChange={handleChange} />
                    </div>
                </Form.Group>
            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="12" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control type="text" placeholder="Address" required  value={formData.address ||""}        onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid address.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="City" required    value={formData.city ||""}       onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid city.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="State" required    value={formData.state ||""}      onChange={handleChange} />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid state.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="zip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="text" placeholder="Zip" required     pattern="[0-9]*"     value={formData.zip ||""}      onChange={handleChange}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid zip.
                    </Form.Control.Feedback>
                </Form.Group>

            </Row>



            <Row className="mb-1 mt-5 text-start">

                <h3>Parent/Legal Guardian Information               </h3>
            </Row>


            <Row className="mb-3 mt-4 text-start">
                <Form.Group as={Col} md="6" controlId="guardianFirstName">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="First name"
value={formData.guardianFirstName ||""}
                        onChange={handleChange}
                    />

                </Form.Group>
                <Form.Group as={Col} md="6" controlId="guardianLastName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    value={formData.guardianLastName ||""}
                    onChange={handleChange}
                />

            </Form.Group>
            </Row>
            <Row className="mb-3 mt-4 text-start">
                <Form.Group as={Col} md="6" controlId="guardianPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Phone"
                        pattern="[0-9]*"
value={formData.guardianPhone ||""}
                        onChange={handleChange}
                    />

                </Form.Group>
                <Form.Group as={Col} md="6" controlId="guardianEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    value={formData.guardianEmail ||""}
                    onChange={handleChange}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    minLength="10"
                />

            </Form.Group>      </Row>
            <Row className="mb-1   text-start">
                <Form.Group as={Col} md="12" controlId="guardianRelationship">
                    <Form.Label>Relationship</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Relationship"
                        value={formData.guardianRelationship ||""}
                        onChange={handleChange}
                    />

                </Form.Group>
            </Row>
            <Row className="mb-1  mt-5 text-start">

                <h3>High School Information
                </h3>
            </Row>
            <Row className="mb-3 text-start">
                <Form.Group as={Col} md="9" controlId="school" >
                    <Form.Label>School</Form.Label>
                    <SchoolListing formData={formData} setFormData={setFormData} />



                </Form.Group>
                <Form.Group as={Col} md="3" controlId="grade">
                    <Form.Label>Current Grade</Form.Label>

                    <Form.Select   onChange={handleChange} required
                                   value={formData.grade}
                    >
                    <option value="">Select</option>
                    <option value="11">11</option>
                    <option value="12">12</option>

                </Form.Select>
            </Form.Group>


            </Row>
            {!validFirstStep && (
            <Button type="button"  onClick={SubmitFirstStep}>Next></Button>
            )}
            </>
            );
            }



            export default FirstStep;
