import logo from '../images/ss-logo.png';


const Header = () => {
    return (
        <>
        <div className="text-center"><img src={logo} alt="Sara Schenirer"/></div>
        <div className="text-center mb-4">
            <h1>  Sara Schenirer Late Dual Credit Course Registration</h1>
{/*<p className="fw-bold alert alert-info">Registration will close THIS SUNDAY, November 10.</p>*/}
{/*           <p> For any questions, reach out to Mrs. Lieba Berger at <a href="mailto:lberger@sarasch.com">lberger@sarasch.com</a></p>*/}

        </div>
        </>
    );
};

export default Header;